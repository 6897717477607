import {
  EntityTransferSelect,
  ENTITY_TYPE,
  useIsThirdGen,
  EntityTransferSelectThirdGen,
} from '@signifyd/components'
import { FC } from 'react'
import { useStoreActions, useStoreState } from 'store'

const TeamSelect: FC = () => {
  const isThirdGen = useIsThirdGen()
  const { currentTeams } = useStoreState((state) => state.user)
  const setCurrentTeams = useStoreActions(
    (action) => action.user.setCurrentTeams
  )

  const TransferSelect = isThirdGen
    ? EntityTransferSelectThirdGen
    : EntityTransferSelect

  return (
    <>
      <TransferSelect
        entityType={ENTITY_TYPE.TEAM}
        selectedKeys={currentTeams.map((team) => team.toString())}
        onSave={(selectedKeys) => {
          const selectedTeams = selectedKeys.map((key) => +key)
          setCurrentTeams(selectedTeams)
        }}
      />
    </>
  )
}

export default TeamSelect
