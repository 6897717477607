import { FC } from 'react'
import { Form } from '@ant-design/compatible'
import { Alert, Checkbox, List } from 'antd'
import { useTranslation } from 'react-i18next'
import { Recipient } from '@signifyd/http'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { Space, Text } from '@signifyd/components'
import { cloneDeep } from 'lodash'
import { toTitleCase } from '@signifyd/utils'
import { formatFullAddress } from 'core/utils/textTransforms'
import { Address } from 'store/caseActions/types/updateAddress.types'
import styles from './UpdateAddressModal.less'

export interface Props {
  addresses: Array<Address>
  setAddresses: (addresses: Array<Address>) => void
}

const UpdateAddressList: FC<Props> = ({ addresses, setAddresses }) => {
  const { t } = useTranslation()

  const processAddress = (
    e: CheckboxChangeEvent,
    recipient: Recipient
  ): void => {
    const matchedAddress = addresses.find(
      (address) => address.oldAddress === recipient
    )

    if (e.target.checked) {
      matchedAddress!.newAddress = cloneDeep(recipient)
    } else {
      matchedAddress!.newAddress = undefined
    }

    setAddresses([...addresses])
  }

  return (
    <>
      <Alert
        type="warning"
        message={t('caseActions.updateAddress.helpText', {
          count: addresses.length,
        })}
        showIcon
      />
      <Space size={20} />
      <Text>{t('caseActions.updateAddress.selectAll')}</Text>
      <Form
        layout="vertical"
        className={styles.addressForm}
        data-test-id="update-address-list"
      >
        <List
          itemLayout="horizontal"
          dataSource={addresses}
          renderItem={(address, index) => {
            const { fullAddress } = address.oldAddress.address

            return (
              <List.Item key={fullAddress}>
                <List.Item.Meta
                  title={
                    <div>
                      <Checkbox
                        className={styles.checkboxSpace}
                        key={fullAddress}
                        onChange={(e) => processAddress(e, address.oldAddress)}
                        data-test-id={`address-checkbox-${index}`}
                        data-analytics-ids="address-checkbox"
                      />
                      <Text strong>
                        {formatFullAddress(address.oldAddress.address)}
                      </Text>
                    </div>
                  }
                  description={
                    <div className={styles.description}>
                      <Text type="secondary">
                        {`${t('caseActions.updateAddress.recipient')}: ${
                          address.oldAddress.fullName
                        }`}
                      </Text>
                      {address.shipment?.shippingMethod && (
                        <Text type="secondary">
                          {`${t(
                            'caseActions.updateAddress.method'
                          )}: ${toTitleCase(address.shipment?.shippingMethod)}`}
                        </Text>
                      )}
                    </div>
                  }
                />
              </List.Item>
            )
          }}
        />
      </Form>
    </>
  )
}

export default UpdateAddressList
