import { NoteMedical } from '@signifyd/components'
import { Popover } from 'antd'
import { FC, useState } from 'react'
import { InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import ActionIconButton from 'pages/CaseReviewPage/components/ActionIconButton/ActionIconButton'
import ThirdGenNoteInput from 'pages/CaseReviewPage/components/ThirdGenNoteInput'

interface Props {
  caseDetails: InvestigationInfo
}

const AddNoteIconPopover: FC<Props> = ({ caseDetails }) => {
  const { t } = useTranslation()

  const [popoverOpen, setIsPopoverOpen] = useState(false)
  const [modalOpen, setIsModalOpen] = useState(false)

  return (
    <Popover
      open={popoverOpen}
      title={t('caseActivity.addNotes')}
      content={
        <ThirdGenNoteInput
          caseId={caseDetails.investigationId}
          isModalOpen={modalOpen}
          onModalOpenChange={(isOpen) => setIsModalOpen(isOpen)}
        />
      }
      placement="bottomRight"
      trigger="click"
      onOpenChange={(open) => {
        if (modalOpen) {
          return
        }

        setIsPopoverOpen(open)
      }}
    >
      <ActionIconButton
        Icon={NoteMedical}
        onClick={() => setIsPopoverOpen(!popoverOpen)}
      />
    </Popover>
  )
}

export default AddNoteIconPopover
