import {
  SearchQuery,
  INV_SEARCH_VIEW,
  SEARCH_FIELD,
  BooleanSearchCondition,
  INV_SEARCH_OPERATOR,
  SEARCH_ORDER,
  DECLINE_REASON_CATEGORY,
  SEARCH_OPERATOR,
  SEARCH_BOOL_OPERATOR,
  FlexibleSearchRequest,
  DOWNLOAD_COLUMN,
} from '@signifyd/http'
import { remove } from 'lodash'
import { FilterState } from 'store/search'
import {
  filterEmptyValues,
  isRangeFilter,
  getOperator,
  buildRangeQuery,
} from './invSearchQueryBuilder.utils'

// This is the maximum pagination/results the backend will allow due to Elastic limitations
const maxElasticResults = 10000

type QueryItem = [string, any]

type ExtendedFields =
  | SEARCH_FIELD.guaranteeRecommendedActionReasons
  | SEARCH_FIELD.guaranteeRecommendedAction

// If we're querying for guaranteeRecommendedActionReasons we need to extend the same query to recommendedActionReasons too
// If we're querying for guaranteeRecommendedAction we need to extend the same query to recommendedAction too
const extendedActions: Record<ExtendedFields, SEARCH_FIELD> = {
  [SEARCH_FIELD.guaranteeRecommendedActionReasons]:
    SEARCH_FIELD.recommendedActionReasons,
  [SEARCH_FIELD.guaranteeRecommendedAction]: SEARCH_FIELD.recommendedAction,
}

function buildExtendedField(
  value: any,
  fieldName: ExtendedFields
): SearchQuery {
  return {
    or: [
      {
        field: {
          fieldName,
          operator: getOperator(fieldName, value),
          values: value,
        },
      },
      {
        field: {
          fieldName: extendedActions[fieldName],
          operator: getOperator(fieldName, value),
          values: value,
        },
      },
    ],
  }
}

function buildQueryFieldsFromArray(
  items: Array<QueryItem>,
  operator: SEARCH_BOOL_OPERATOR
): SearchQuery {
  items = items.filter(([, value]) => filterEmptyValues(value))

  // Needed for cyclical function calls in recursive query structures.
  if (items.length === 0) {
    throw Error('No query fields')
  }
  if (items.length === 1) {
    return buildQueryField(items[0])
  }

  return { [operator]: items.map(buildQueryField) } as BooleanSearchCondition
}

function buildQueryField([key, value]: QueryItem): SearchQuery {
  let fieldName = key as unknown as SEARCH_FIELD
  if (key === 'searchTerm') {
    fieldName = SEARCH_FIELD.general
  }

  if (key === 'teamId') {
    return {
      field: { fieldName, operator: SEARCH_OPERATOR.IN, values: value },
    }
  }

  if (Array.isArray(value)) {
    const queryItems: Array<QueryItem> = value.map((item) => [key, item])

    return buildQueryFieldsFromArray(queryItems, SEARCH_BOOL_OPERATOR.OR)
  }

  if (isRangeFilter(value)) {
    return buildRangeQuery(value as RangeObject, fieldName)
  }

  if (value === INV_SEARCH_OPERATOR.NULL) {
    return {
      field: { fieldName, operator: SEARCH_OPERATOR.NULL, values: [] },
    }
  }

  if (value === INV_SEARCH_OPERATOR.NOT_NULL) {
    return {
      field: { fieldName, operator: SEARCH_OPERATOR.NOT_NULL, values: [] },
    }
  }

  // If current query requires invoking another field query
  if (extendedActions[fieldName as ExtendedFields]) {
    return buildExtendedField(value, fieldName as ExtendedFields)
  }

  return {
    field: {
      fieldName,
      operator: getOperator(fieldName, value),
      values: value,
    },
  }
}

type FilterStateMap = Partial<{
  [key in SEARCH_FIELD]: any
}>

interface Props<F extends FilterStateMap, V> {
  searchTerm?: string
  sort?: {
    by: keyof F
    order: SEARCH_ORDER
  }
  pagination?: { size: number; currentPage: number }
  filters?: F
  view: V
  columns?: [DOWNLOAD_COLUMN]
}

export const getQueryItemsFromFilters = <T extends FilterState>(
  filters?: Partial<T>
): Array<QueryItem> => {
  if (!filters) {
    return []
  }

  const queryItems = Object.entries(filters) as Array<QueryItem>

  return queryItems
}

export default function invSearchQueryBuilder<
  F extends FilterStateMap,
  V extends INV_SEARCH_VIEW,
>({
  searchTerm,
  filters,
  sort,
  pagination,
  view,
  columns,
}: Props<F, V>): FlexibleSearchRequest<V> {
  const queryItems = getQueryItemsFromFilters({ searchTerm, ...filters })

  const externalQueryItems = remove(
    queryItems,
    ([filter]) =>
      filter === SEARCH_FIELD.guaranteeRecommendedActionRuleId ||
      filter === SEARCH_FIELD.recommendedActionRuleId
  )

  // when filtering by action, we only want to see decision center cases
  if (filters?.guaranteeRecommendedAction?.length > 0) {
    queryItems.push([
      SEARCH_FIELD.guaranteeRecommendedActionReasons,
      [DECLINE_REASON_CATEGORY.CUSTOMER_POLICY],
    ])
  }

  const query = buildQueryFieldsFromArray(queryItems, SEARCH_BOOL_OPERATOR.AND)

  const searchQuery: FlexibleSearchRequest<V> = {
    query,
    view,
  }

  if (
    filters?.guaranteeRecommendedActionRuleId &&
    filters?.recommendedActionRuleId
  ) {
    const externalFilterQuery = buildQueryFieldsFromArray(
      externalQueryItems,
      SEARCH_BOOL_OPERATOR.OR
    )

    searchQuery.query = {
      [SEARCH_BOOL_OPERATOR.AND]: [query, externalFilterQuery],
    }
  }

  if (sort) {
    searchQuery.sort = { fieldName: sort.by.toString(), order: sort.order }
  }

  if (pagination) {
    const { size, currentPage } = pagination
    let offset = (currentPage - 1) * size
    // Check for overflow issues (10.000 backend max limit)
    if (offset + size > maxElasticResults) {
      const overflow = size + offset
      const overflowAmount = overflow - maxElasticResults

      offset -= overflowAmount
    }

    searchQuery.paging = { size, offset }
  }

  if (columns) {
    searchQuery.columns = columns
  }

  return searchQuery
}
