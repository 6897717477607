import { useMutation, UseMutationResult } from '@tanstack/react-query'
import { message } from 'antd'
import { isNil } from 'lodash'
import moment from 'moment-timezone'
import {
  createReroute,
  CreateRerouteRequest,
  CreateRerouteResponse,
  INV_CASE_TYPE,
  INV_GUARANTEE_DISPOSITION,
  InvestigationInfo,
  User,
} from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { i18nInstance } from '@signifyd/components'
import { CASE_CAPABILITIES, hasCapability } from 'core/utils/capabilities'
import { isTransportAirline } from 'core/utils/isTransportAirline/isTransportAirline'
import { getPastStartDate } from 'core/utils/date.utils'
import {
  disableAction,
  enableAction,
  hideAction,
} from 'store/caseActions/caseActions.utils'
import { toRerouteRequest } from 'store/caseActions/utils'
import { useStoreActions } from 'store'
import { Address } from 'store/caseActions/types/updateAddress.types'
import { parseAPIErrorMsg } from '../utils/parseAPIErrorMsg'

const translationPrefix = 'store.caseActions.updateAddress'

interface ActionState {
  disabled: boolean
  hidden?: boolean
  disabledReason?: string
}

export const canUpdateAddress = (
  caseDetails: InvestigationInfo,
  user: User | null
): ActionState => {
  if (user?.isAdmin && !isTransportAirline(caseDetails.products)) {
    return enableAction()
  }

  const maxDays = hasCapability(
    caseDetails.teamId,
    caseDetails.customerId,
    CASE_CAPABILITIES.ALLOW_180_DAYS_FOR_ADDRESS_UPDATES
  )
    ? 180
    : 14

  const hasMultipleUpdates = hasCapability(
    caseDetails.teamId,
    caseDetails.customerId,
    CASE_CAPABILITIES.ALLOW_10_ADDRESS_UPDATES
  )

  const maxUpdates = hasMultipleUpdates ? 10 : 1

  const allowedDispositions = hasMultipleUpdates
    ? [
        INV_GUARANTEE_DISPOSITION.PENDING,
        INV_GUARANTEE_DISPOSITION.APPROVED,
        INV_GUARANTEE_DISPOSITION.DECLINED,
        INV_GUARANTEE_DISPOSITION.IN_REVIEW,
      ]
    : [INV_GUARANTEE_DISPOSITION.APPROVED, INV_GUARANTEE_DISPOSITION.DECLINED]

  if (
    // Hide if guarantee disposition is not one of the following
    caseDetails.guaranteeDisposition &&
    !allowedDispositions.includes(
      caseDetails.guaranteeDisposition as INV_GUARANTEE_DISPOSITION
    )
  ) {
    return hideAction()
  }

  // 1. Disable if test order
  if (caseDetails.isTestInvestigation) {
    return disableAction(i18nInstance.t(`${translationPrefix}.testCase`))
  }

  // 2. Disable is Trial order
  if (caseDetails.caseType === INV_CASE_TYPE.TRIAL) {
    return disableAction(i18nInstance.t(`${translationPrefix}.trialCase`))
  }

  // 3. Disable if has an active claim against it
  if (caseDetails.claims.length > 0) {
    return disableAction(i18nInstance.t(`${translationPrefix}.hasClaim`))
  }

  // 4. Disable if order is older than 14/180 days
  if (
    moment(caseDetails.normalizedPurchaseCreatedAt).isBefore(
      getPastStartDate({ subtractInterval: 'days', subtractValue: maxDays })
    )
  ) {
    return disableAction(
      i18nInstance.t(`${translationPrefix}.tooOld`, { days: maxDays })
    )
  }

  // 5. Disable if not yet submitted for guarantee
  if (isNil(caseDetails.guaranteeDisposition)) {
    return enableAction()
  }

  // 6. Disable if decision count > 1, orders can only be re-decisioned once.
  if (
    !!caseDetails.guaranteeDecisionCount &&
    caseDetails.guaranteeDecisionCount > maxUpdates
  ) {
    return disableAction(i18nInstance.t(`${translationPrefix}.alreadyUpdated`))
  }

  // 7. Disable if the case doesn't have a team id (necessary for reroute call)
  if (!caseDetails.teamId) {
    return disableAction(i18nInstance.t('caseActions.updateAddress.noTeamId'))
  }

  if (isTransportAirline(caseDetails.products)) {
    return hideAction()
  }

  // If we're here, action can be seen and used
  return enableAction()
}

export const useUpdateAddress = (
  user: User | null
): UseMutationResult<
  CreateRerouteResponse,
  unknown,
  {
    caseDetails: InvestigationInfo
    addresses: Array<Address>
  },
  unknown
> => {
  const { t } = useTranslation()
  const { refreshCurrentCase } = useStoreActions(
    (actions) => actions.currentCase
  )

  return useMutation({
    mutationFn: async (requestPayload: {
      caseDetails: InvestigationInfo
      addresses: Array<Address>
    }) => {
      const { disabled, hidden, disabledReason } = canUpdateAddress(
        requestPayload.caseDetails,
        user
      )

      if (disabled || hidden) {
        throw new Error(disabledReason || t(`${translationPrefix}.notAllowed`))
      }

      const rerouteRequest: CreateRerouteRequest = toRerouteRequest(
        requestPayload.addresses,
        requestPayload.caseDetails
      )
      const { data } = await createReroute(
        rerouteRequest,
        requestPayload.caseDetails.teamId
      )

      return data
    },
    onSuccess: () => {
      refreshCurrentCase()
      message.success(t(`${translationPrefix}.apiSuccess`))
    },
    onError: (error) => {
      message.error(
        parseAPIErrorMsg(error) || t(`${translationPrefix}.apiFailure`)
      )
    },
  })
}
