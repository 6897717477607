import { FC, useMemo, useState } from 'react'
import { Space } from '@signifyd/components'
import { useTranslation } from 'react-i18next'
import { NOTE_TYPE } from '@signifyd/http'
import { Flex } from 'antd'
import { useStoreState } from 'store'
import EmptyWarning from 'core/components/EmptyWarning'
import NoteTimeline from './NoteTimeline'
import NoteFilter from './NoteFilter'
import styles from './CaseActivityThirdGen.less'
import { FILTER_TYPE } from './CaseActivity.types'

export const CaseActivityThirdGen: FC = () => {
  const { t } = useTranslation()

  const [noteFilter, setNoteFilter] = useState<null | FILTER_TYPE>(null)
  const allNotes = useStoreState((state) => state.currentCase.notes)
  const notesError = useStoreState((state) => state.currentCase.notesError)

  const filteredNotes = useMemo(() => {
    if (!noteFilter) {
      return allNotes
    }

    return allNotes.filter(
      ({ noteType }) =>
        (noteFilter === FILTER_TYPE.EVENTS &&
          noteType === NOTE_TYPE.SIGNIFYD_EVENTS) ||
        (noteFilter === FILTER_TYPE.NOTES &&
          noteType !== NOTE_TYPE.SIGNIFYD_EVENTS)
    )
  }, [noteFilter, allNotes])

  return (
    <Flex className={styles.activityPanel} vertical>
      <div className={styles.fixed}>
        <NoteFilter
          noteFilter={noteFilter}
          setNoteFilter={setNoteFilter}
          filteredNotesCount={filteredNotes.length}
        />
        <Space size="sm" />
      </div>

      <div className={styles.scrollable}>
        {notesError ? (
          <EmptyWarning text={t('caseActivity.noNoteError')} />
        ) : (
          <NoteTimeline filteredNotes={filteredNotes} />
        )}
      </div>
    </Flex>
  )
}

export default CaseActivityThirdGen
