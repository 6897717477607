import { FC } from 'react'
import { BullHorn, NoteSticky, Space } from '@signifyd/components'
import { colorYonder, colorMarengo } from '@signifyd/colors'
import { Timeline, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import { CaseNote, NOTE_TYPE } from '@signifyd/http'
import { useStoreState } from 'store'
import styles from './NoteTimeline.less'
import NoteTimelineContent from './NoteTimelineContent'
import NoteTimelineHeader from './NoteTimelineHeader'

interface Props {
  filteredNotes: Array<CaseNote>
}

export const NoteTimeline: FC<Props> = ({ filteredNotes }) => {
  const { t } = useTranslation()
  const caseDetails = useStoreState((state) => state.currentCase.details)

  if (!caseDetails) {
    return null
  }

  const noteElements = filteredNotes.map((note) => {
    const { noteId, noteType } = note

    const isEvent = noteType === NOTE_TYPE.SIGNIFYD_EVENTS

    return (
      <Timeline.Item
        key={noteId}
        color={colorYonder}
        dot={
          <Tooltip
            placement="top"
            title={isEvent ? t('caseActivity.event') : t('caseActivity.note')}
          >
            {isEvent ? (
              <BullHorn fill="#000000" />
            ) : (
              <NoteSticky fill={colorMarengo} />
            )}
          </Tooltip>
        }
      >
        <div className={styles.wrapper}>
          <NoteTimelineHeader note={note} caseDetails={caseDetails} />
          <Space size={4} />
          <NoteTimelineContent note={note} />
        </div>
      </Timeline.Item>
    )
  })

  // Style required unfortunately
  return (
    <Timeline style={{ marginLeft: '3px', marginTop: '6px' }}>
      {noteElements}
    </Timeline>
  )
}

export default NoteTimeline
