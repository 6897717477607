import { SEARCH_FIELD } from '@signifyd/http'

const columnSelectKeys: Partial<Record<SEARCH_FIELD, string>> = {
  recommendedDecisionDisposition: 'Recommendation',
  guaranteeDisposition: 'Guarantee',
  signifydScore: 'Score',
  investigationId: 'Case ID',
  orderId: 'Order ID',
  recordLocator: 'Record locator',
  guaranteeRecommendedAction: 'Checkout',
  returnDecisionCheckpointAction: 'Return',
  normalizedPurchaseCreatedAt: 'Date',
  orderTotalAmount: 'Amount',
  shippingMethod: 'Shipping method',
  authorizationGatewayStatus: 'Auth status',
  investigationReviewDisposition: 'Order review flag',
  caseStatus: 'Case status',
  caseType: 'Case type',
  checkoutToken: 'Checkout token',
  isTestInvestigation: 'Test cases',
  claimStatus: 'Claim status',
  recipientFullName: 'Shopper name',
  sellerId: 'Seller ID',
  sellerName: 'Seller name',
  sellerParentEntity: 'Parent entity',
  leadPassenger: 'Lead passenger',
  departurePortCode: 'Departure airport',
  arrivalPortCode: 'Arrival airport',
}

export default {
  title: 'Orders',
  noOrders: {
    title: 'No orders found',
    subTitle1: '1 or more search keywords were not found.',
    subTitle2:
      'Try adjusting your search to correct typos, or adjust the filter criteria.',
    subTitle3:
      'Try expanding your date range beyond 360 days when filtering for an older date.',
    description: 'Unable to find orders within selected timeframe.',
    description1: 'We recommend you try another timeframe',
  },
  table: {
    title_one: '{{count}} result found',
    title_other: '{{count}} results found',
    currentRange: '{{start}}-{{end}} of {{total}} results',
    header: {
      recommendation: 'Recommendation',
      guarantee: 'Guarantee',
      score: 'Score',
      caseId: 'Case ID',
      orderId: 'Order ID',
      recipientFullName: 'Shopper name',
      date: 'Date',
      amount: 'Amount',
      authStatus: 'Auth Status',
      caseFlag: 'Order review flag',
      checkout: 'Checkout',
      return: 'Return',
      caseStatus: 'Case status',
      caseType: 'Case type',
      checkoutToken: 'Checkout token',
      claimStatus: 'Claim status',
      shippingMethod: 'Shipping method',
      sellerId: 'Seller ID',
      sellerName: 'Seller name',
      sellerParentEntity: 'Parent entity',
      recordLocator: 'Record locator',
      leadPassenger: 'Lead passenger',
      departureAirport: 'Departure airport',
      arrivalAirport: 'Arrival airport',
    },
    contextMenu: {
      investigation: 'Case ID: {{investigationId}}',
      openNewTab: 'Open in new tab',
      openNewWindow: 'Open in new window',
    },
    tooltip: {
      arrivalAirport:
        'This is the arrival airport of \n the last leg in the first slice',
    },
    caseFlag: {
      GOOD: 'Good',
      FRAUDULENT: 'Bad',
    },
    noMatch: 'No match',
    testCase: 'Test case',
    andXMore: '{{claimStatus}} & {{count}} more',
  },
  columnSelect: {
    drawerTitle: 'Columns',
    drawerTitleHidden: 'Hidden columns ({{count}})',
    visibleColumns: 'Visible columns',
    hiddenColumns: 'Hidden columns',
    empty: {
      header: 'Hidden columns will appear here',
      value: 'To hide any column from the table, select Hide.',
    },
    apply: 'Apply',
    cancel: 'Cancel',
    close: {
      reset: 'Reset to default',
      confirmTitle: 'Cancel changes to columns?',
      confirmText: 'Any changes will be lost.',
      confirmClose: 'Cancel changes',
      no: 'Keep',
    },
    showColumn: 'Show',
    hideColumn: 'Hide',
    tooltip: 'Default columns cannot be hidden',
    keys: columnSelectKeys,
  },
  downloadButton: {
    title: 'Download',
    unavailable: 'Download started',
    failure: 'Apologies — order download failed, try again',
  },
  noMatch: 'No match',
  returnTag: {
    accept: 'Accept',
    reject: 'Reject',
    challenge: 'Challenge',
    credit: 'Credit',
    hold: 'Hold',
  },
  exceedMaxResultsTooltip:
    'Too many results to show. To display results, we recommend refining the search further.',
}
