import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { Flex, Tooltip, Typography } from 'antd'
import { TextThirdGen } from '@signifyd/components'
import { spacingMD, spacingXS } from '@signifyd/ant'
import { useStoreState, useStoreActions } from 'store'
import { SavedFilter } from 'store/search'
import ThirdGenSavedFilterItem from './ThirdGenSavedFilterItem'
import styles from './ThirdGenSavedFilters.less'

interface Props {
  onClick: (key: string) => void
  matchingSavedFilter?: SavedFilter
}

const ThirdGenSavedFilters: FC<Props> = ({ onClick, matchingSavedFilter }) => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'search.quickSearch',
  })
  const savedFilters = useStoreState((state) => state.search.savedFilters)
  const deleteSavedFilter = useStoreActions(
    (actions) => actions.search.deleteSavedFilter
  )

  const savedFiltersArray = Object.entries(savedFilters)

  return (
    <Flex gap={spacingMD} vertical>
      <Flex className={styles.tooltipContainer} gap={spacingXS}>
        <Typography.Title level={3}>{t('savedFilters')}</Typography.Title>
        <Tooltip
          arrow={{ pointAtCenter: true }}
          overlayClassName={styles.savedFiltersTooltipOverlay}
          title={
            <TextThirdGen size="sm" className={styles.savedFiltersTooltip}>
              {t('savedFiltersTooltip')} <b>{t('savedFiltersDateNote')}</b>
            </TextThirdGen>
          }
          placement="topRight"
        >
          <QuestionCircleOutlined />
        </Tooltip>
      </Flex>

      {!savedFiltersArray.length && (
        <TextThirdGen className={styles.noSavedFilters}>
          {t('noSavedFilters')}
        </TextThirdGen>
      )}
      <div className={styles.filterContainer}>
        {savedFiltersArray.map(([key, { title }]) => (
          <ThirdGenSavedFilterItem
            onClick={onClick}
            onDelete={deleteSavedFilter}
            itemKey={key}
            title={title}
            key={key}
            isCurrentFilter={title === matchingSavedFilter?.title}
          />
        ))}
      </div>
    </Flex>
  )
}
export default ThirdGenSavedFilters
