export default {
  drawerTitle: 'Order activity',
  tabTitle: 'Activity',
  addNotesPlaceholder: 'Add notes',
  maxLength: '{{count}} character over. Maximum {{limit}} characters.',
  addNotes: 'Add notes',
  deleteNote: 'Delete note',
  cancelDeleteNote: 'Cancel',
  confirmDeleteTitle: 'Delete note?',
  undoneWarning: "This can't be undone",
  notes: 'Notes',
  notesCount: 'Notes ({{count}}) ',
  events: 'Events',
  eventsCount: 'Events ({{count}}) ',
  note: 'Note',
  noNoteError: 'Apologies — case notes failed to load. Refresh to try again.',
  event: 'Event',
  signifyd: 'Signifyd',
  agent: 'Signifyd agent',
  allActivities: 'All activities',
  allActivitiesCount: 'All activities ({{count}}) ',
  cancelButton: 'Cancel',
  comments: 'Comments',
  orderReviewChecklist: 'Order review checklist',
  invalidJSON: 'Invalid JSON',
  fileExpired:
    "Uploaded files older than 14 days are removed in line with Signifyd's security policies",
  fileNote:
    '<span class="note-with-files"><strong>File Attached: </strong></span><div class="files-wrap"><a title="{{name}}" target="_blank noopener noreferrer" href="{{- url}}">{{name}}</a></div>',
  expiredNoteTooltip:
    "Uploaded files older than 14 days are removed in line with Signifyd's security policies",
  fileNoteLabel: 'File(s) attached',
}
