import { FC, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Flex, Popconfirm } from 'antd'
import { T4, Text } from '@signifyd/components'
import { isEqual, cloneDeep } from 'lodash'
import { spacingXS } from '@signifyd/ant'
import styles from './ThirdGenTableColumnSelectDrawer.less'
import { ColumnConfig, getDefaultColumnConfigs } from '../columns'

interface Props {
  columns: Array<ColumnConfig>
  onSetColumns: (columns: Array<ColumnConfig>) => void
  clearChanges: () => void
  applyChanges: () => void
  hasChanges: boolean
  handleConditionallyClear: () => void
  hasAirlineOrders?: boolean
}

const TableColumnSelectFooter: FC<Props> = ({
  columns,
  onSetColumns,
  clearChanges,
  applyChanges,
  hasChanges,
  handleConditionallyClear,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'pages.results' })

  const isDefaultSet = useMemo(() => {
    return isEqual(columns, getDefaultColumnConfigs())
  }, [columns])

  return (
    <Flex align="center" justify="space-between" className={styles.footer}>
      <Button
        data-test-id="resetColumnsButton"
        onClick={() => {
          onSetColumns(cloneDeep(getDefaultColumnConfigs(hasAirlineOrders)))
        }}
        type="link"
        disabled={isDefaultSet}
      >
        {t('columnSelect.close.reset')}
      </Button>
      <Flex gap={spacingXS}>
        <Popconfirm
          overlayClassName="sig-popconfirm"
          icon={null}
          disabled={hasChanges}
          title={
            <div data-test-id="drawerCloseConfirm">
              <T4>{t('columnSelect.close.confirmTitle')}</T4>
              <Text type="secondary">
                {t('columnSelect.close.confirmText')}
              </Text>
            </div>
          }
          onConfirm={clearChanges}
          okText={t('columnSelect.close.confirmClose')}
          cancelText={t('columnSelect.close.no')}
        >
          <Button
            className={styles.cancelButton}
            data-test-id="cancelButtonBottom"
            onClick={handleConditionallyClear}
            type="text"
          >
            {t('columnSelect.cancel')}
          </Button>
        </Popconfirm>
        <Button
          onClick={applyChanges}
          disabled={hasChanges}
          data-test-id="okApplyButton"
          type="primary"
        >
          {t('columnSelect.apply')}
        </Button>
      </Flex>
    </Flex>
  )
}

export default TableColumnSelectFooter
