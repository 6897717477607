import React from 'react'
import ReactDOM, { render } from 'react-dom'
import { StoreProvider } from 'easy-peasy'
import {
  ErrorBoundary,
  AuthProvider,
  defaultQueryClientConfig,
  ThirdGenToggleProvider,
} from '@signifyd/components'
import { SplitFactoryProvider } from '@splitsoftware/splitio-react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { splitIoStagingKey, splitIoProdKey } from '@signifyd/sig-keys'
import UserLoader from 'UserLoader'
import App from 'App'
import store from 'store'
import 'antd/dist/reset.css'
import '@signifyd/ant/dist/index.less'
import styles from './index.less'

export const queryClient = new QueryClient(defaultQueryClientConfig)

if (process.env.NODE_ENV === 'development') {
  import('@axe-core/react').then((axe) => {
    axe.default(React, ReactDOM, 1000, {}, '#root')
  })
}

const sdkConfig: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey:
      process.env.BUILD_ENV === 'production'
        ? splitIoProdKey
        : splitIoStagingKey,
    key: 'dummy_key',
  },
}

render(
  <AuthProvider>
    <ErrorBoundary className={styles.errorBoundary}>
      <SplitFactoryProvider config={sdkConfig}>
        <StoreProvider store={store}>
          <QueryClientProvider client={queryClient}>
            <UserLoader>
              <ThirdGenToggleProvider>
                <App />
              </ThirdGenToggleProvider>
            </UserLoader>
          </QueryClientProvider>
        </StoreProvider>
      </SplitFactoryProvider>
    </ErrorBoundary>
  </AuthProvider>,
  document.getElementById('root')
)
