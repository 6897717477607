import { FC, useState } from 'react'
import { Icon as LegacyIcon } from '@ant-design/compatible'
import { Button, Flex, Tooltip } from 'antd'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { spacingSM } from '@signifyd/ant'
import { TextThirdGen } from '@signifyd/components'
import styles from './ThirdGenSavedFilterItem.less'

interface Props {
  onClick: (key: string) => void
  onDelete: (key: string) => Promise<void>
  title: string
  itemKey: string
  isCurrentFilter: boolean
}

const ThirdGenSavedFilterItem: FC<Props> = ({
  onClick,
  onDelete,
  title,
  itemKey,
  isCurrentFilter,
}) => {
  const { t } = useTranslation()
  const [deleting, setDeleting] = useState(false)

  const deleteHandler = (): void => {
    setDeleting(true)
    onDelete(itemKey).catch(() => setDeleting(false))
  }

  const clickHandler = (): void => onClick(itemKey)

  return (
    <Flex
      align="center"
      justify="space-between"
      className={styles.savedFilterItem}
    >
      <Flex align="center" gap={spacingSM}>
        <Button
          data-analytics-id="saved-filter"
          data-test-id="saved-filter"
          type="link"
          onClick={clickHandler}
          className={cx([styles.button, styles.text])}
        >
          {title}
        </Button>
        {isCurrentFilter && (
          <TextThirdGen
            data-test-id={`selectedFilter-${title}`}
            className={styles.selected}
          >
            {t('filters.selected')}
          </TextThirdGen>
        )}
      </Flex>
      <Tooltip title={t('filters.delete')}>
        <Button
          type="link"
          className={cx([
            styles.button,
            styles.delete,
            deleting && styles.visible,
          ])}
          onClick={deleteHandler}
        >
          <LegacyIcon
            data-test-id={
              deleting ? 'deleteFilterLoadingIcon' : 'deleteFilterIcon'
            }
            type={deleting ? 'loading' : 'delete'}
          />
        </Button>
      </Tooltip>
    </Flex>
  )
}

export default ThirdGenSavedFilterItem
