import { i18nInstance } from '@signifyd/components'
import {
  CLAIM_DISPOSITION,
  CLAIM_STATUS,
  INV_GUARANTEE_DISPOSITION,
  SEARCH_FIELD,
} from '@signifyd/http'
import { FilterState } from 'store/search'
import { AggregateResponse } from '../../../queries/useSearchAggregates'
import { SubTotal } from './AggregateView'
import { handleArrayChange } from '../aggregate.utils'

export const getSubTotals = (
  { orderData, claimData }: AggregateResponse,
  filters: FilterState
): Array<SubTotal> => {
  return [
    {
      title: i18nInstance.t('aggregates.guarantee.approved'),
      value: `${orderData.approvedCount}`,
      newFilter: { field: INV_GUARANTEE_DISPOSITION.APPROVED },
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.APPROVED
      ),
      id: 'aggregateGuaranteeApproved',
    },
    {
      title: i18nInstance.t('aggregates.guarantee.declined'),
      value: `${orderData.declinedCount}`,
      newFilter: { field: INV_GUARANTEE_DISPOSITION.DECLINED },
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.DECLINED
      ),
      id: 'aggregateGuaranteeDeclined',
    },
    {
      title: i18nInstance.t('aggregates.guarantee.canceled'),
      value: `${orderData.canceledCount}`,
      newFilter: { field: INV_GUARANTEE_DISPOSITION.CANCELED },
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.CANCELED
      ),
      id: 'aggregateGuaranteeCanceled',
    },
    {
      title: i18nInstance.t('aggregates.guarantee.pending'),
      value: `${orderData.pendingCount}`,
      newFilter: { field: INV_GUARANTEE_DISPOSITION.PENDING },
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.PENDING
      ),
      id: 'aggregateGuaranteePending',
    },
    {
      title: i18nInstance.t('aggregates.guarantee.inReview'),
      value: `${orderData.inReviewCount}`,
      newFilter: { field: INV_GUARANTEE_DISPOSITION.IN_REVIEW },
      isSelected: filters.guaranteeDisposition.includes(
        INV_GUARANTEE_DISPOSITION.IN_REVIEW
      ),
      id: 'aggregateGuaranteeInReview',
    },
    {
      title: i18nInstance.t('aggregates.claim.approved'),
      value: claimData.approvedCount,
      newFilter: {
        claims: {
          [SEARCH_FIELD.claimDisposition]: handleArrayChange(
            CLAIM_DISPOSITION.APPROVED,
            filters.claimDisposition
          ),
        },
      },
      isSelected: filters.claimDisposition.includes(CLAIM_DISPOSITION.APPROVED),
      id: 'aggregateClaimApproved',
    },
    {
      title: i18nInstance.t('aggregates.claim.declined'),
      value: claimData.declinedCount,
      newFilter: {
        claims: {
          [SEARCH_FIELD.claimDisposition]: handleArrayChange(
            CLAIM_DISPOSITION.DECLINED,
            filters.claimDisposition
          ),
        },
      },
      isSelected: filters.claimDisposition.includes(CLAIM_DISPOSITION.DECLINED),
      id: 'aggregateClaimDeclined',
    },
    {
      title: i18nInstance.t('aggregates.claim.needsMoreInfo'),
      value: claimData.needsMoreInfoCount,
      newFilter: {
        claims: {
          [SEARCH_FIELD.claimStatus]: handleArrayChange(
            CLAIM_STATUS.NEED_MORE_INFO,
            filters.claimStatus
          ),
        },
      },
      isSelected: filters.claimStatus.includes(CLAIM_STATUS.NEED_MORE_INFO),
      id: 'aggregateClaimNeedsMoreInfo',
    },
  ]
}
