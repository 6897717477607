import { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  canPerformAction,
  useClaimReimbursement,
} from 'core/hooks/caseActions/useClaimReimbursement/useClaimReimbursement'
import {
  ACTION_CONTEXT,
  ClaimReimbursementRequiredFields,
} from 'core/hooks/caseActions/useClaimReimbursement/types'
import ClaimReimbursementModal from 'pages/CaseReviewPage/containers/ClaimReimbursementModal'
import ActionButton from '../ActionButton'

interface Props {
  caseDetails: ClaimReimbursementRequiredFields
}

export const MoreOptionsClaimReimbursementButton: FC<Props> = ({
  caseDetails,
}) => {
  const { t } = useTranslation()

  const [modalVisible, setModalVisible] = useState(false)

  const {
    apiErrors,
    isLoading,
    performAction,
    reset: resetApiCall,
  } = useClaimReimbursement(ACTION_CONTEXT.CaseSearch)

  const actionState = useMemo(
    () => canPerformAction(caseDetails),
    [caseDetails]
  )

  return (
    <>
      <ActionButton
        onClick={() => setModalVisible(true)}
        actionState={actionState}
        isLoading={isLoading}
        text={t('caseActions.claimReimbursement')}
      />

      <ClaimReimbursementModal
        apiErrors={apiErrors}
        caseDetails={caseDetails}
        closeModal={() => {
          setModalVisible(false)
          resetApiCall()
        }}
        isLoading={isLoading}
        performAction={performAction}
        visible={modalVisible}
      />
    </>
  )
}

export default MoreOptionsClaimReimbursementButton
