import { FC } from 'react'
import { Text, useIsThirdGen } from '@signifyd/components'
import { spacingSM } from '@signifyd/ant'
import { Flex, Typography } from 'antd'
import styles from './SummaryItem.less'

interface Props {
  label: string
  value: StrOrNum
}

const SummaryItem: FC<Props> = ({ label, value }) => {
  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <Flex gap={spacingSM}>
        <Typography.Title className={styles.title} level={4}>
          {label}:
        </Typography.Title>
        <Typography.Title level={3}>{value}</Typography.Title>
      </Flex>
    )
  }

  return (
    <span>
      <Text transform="uppercase" size="xs">
        {label}:{' '}
      </Text>
      <Text size="lg">{value}</Text>
    </span>
  )
}

export default SummaryItem
