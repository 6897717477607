import {
  updateCase,
  INV_REVIEW_DISPOSITION,
  InvestigationInfo,
} from '@signifyd/http'
import {
  useMutation,
  UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query'
import { message } from 'antd'
import { i18nInstance } from '@signifyd/components'
import { Actions, useStoreActions } from 'easy-peasy'
import { FileNoteUpdate } from 'store/currentCase/types/case.notes.types'
import {
  disableAction,
  enableAction,
} from 'store/caseActions/caseActions.utils'
import { StoreModel } from 'store'
import { GET_CASE_ENTRIES_QUERY_KEY } from './useCaseEntries'
import { ActionState } from './caseActions/utils'

const translationPrefix = 'store.caseActions.orderFeedback'

interface UpdateCasePayload {
  caseDetails: InvestigationInfo
  feedback: INV_REVIEW_DISPOSITION
  notes?: FileNoteUpdate
}

export const canSetCaseFeedback = (
  caseDetails: InvestigationInfo
): ActionState => {
  if (caseDetails.claims.length) {
    return disableAction(i18nInstance.t(`${translationPrefix}.hasClaim`))
  }

  return enableAction()
}

export const useCaseFeedback = (): UseMutationResult<
  void,
  unknown,
  UpdateCasePayload
> => {
  const { addNoteWithFiles } = useStoreActions(
    (actions: Actions<StoreModel>) => actions.currentCase
  )

  const { refreshCurrentCase } = useStoreActions(
    (actions: Actions<StoreModel>) => actions.currentCase
  )

  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (caseFeedbackDetails: UpdateCasePayload) => {
      const { caseDetails, feedback, notes } = caseFeedbackDetails

      if (notes) {
        await addNoteWithFiles(notes)
      }

      await updateCase(caseDetails.investigationId, {
        reviewDisposition: feedback,
      })
    },
    onSuccess: (_, { caseDetails }) => {
      refreshCurrentCase()
      queryClient.invalidateQueries([
        GET_CASE_ENTRIES_QUERY_KEY,
        caseDetails.investigationId,
      ])
      message.success(i18nInstance.t(`${translationPrefix}.apiSuccess`))
    },
    onError: () => {
      message.error(i18nInstance.t(`${translationPrefix}.apiFailure`))
    },
  })
}
