import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { RECOMMENDED_DECISION_DISPOSITION } from '@signifyd/http'
import SummaryItem from '../SummaryItem'

interface Props {
  values: Array<RECOMMENDED_DECISION_DISPOSITION>
}

const RecommendedStatusItem: FC<Props> = ({ values }) => {
  const { t } = useTranslation()

  return (
    <SummaryItem
      label={t('filters.recommendedAction.label')}
      value={values
        .map((item) => t(`filters.recommendedDecisionDisposition.${item}`))
        .join(', ')}
    />
  )
}

export default RecommendedStatusItem
