import { FC } from 'react'
import { Flex } from 'antd'
import { Shipment } from '@signifyd/http'
import { compact, uniq, map } from 'lodash'
import { toTitleCase } from '@signifyd/utils'
import { spacingSM } from '@signifyd/ant'
import SummaryIconThirdGen from '../SummaryIconThirdGen'
import styles from './ShippingSummaryLabel.less'

interface Props {
  sectionName: string
  shipments: Array<Shipment>
}

const ShippingSummaryLabel: FC<Props> = ({ sectionName, shipments }) => {
  const shippers = compact(
    uniq([...map(shipments, 'shipper'), ...map(shipments, 'shippingMethod')])
  )

  return (
    <>
      <Flex justify="space-between" align="center" className={styles.container}>
        <span>{sectionName}</span>
        <Flex gap={spacingSM}>
          {shippers.map((shipper, key) => (
            <SummaryIconThirdGen
              key={key}
              type={shipper}
              tooltip={toTitleCase(shipper)}
            />
          ))}
        </Flex>
      </Flex>
    </>
  )
}

export default ShippingSummaryLabel
