import { action, thunk } from 'easy-peasy'
import { i18nInstance } from '@signifyd/components'
import {
  listCaseNotes,
  createCaseNote,
  deleteCaseNote,
  attachFileToInvestigationNote,
} from '@signifyd/http'
import { message } from 'antd'
import { CaseNotesModel } from './types/case.notes.types'

const caseNotesModel: CaseNotesModel = {
  // State
  loadingNotes: false,
  notesError: false,
  notes: [],

  // Getters / Setters
  setLoadingNotes: action((state, payload) => {
    state.loadingNotes = payload
  }),

  setNotesError: action((state, payload) => {
    state.notesError = payload
  }),

  setCaseNotes: action((state, payload) => {
    state.notes = payload
  }),

  // Http Stuff
  getCaseNotes: thunk((actions, caseId) => {
    actions.setLoadingNotes(true)
    actions.setNotesError(false)

    return listCaseNotes(caseId)
      .then(({ data: { notes } }) => {
        actions.setCaseNotes(notes)
      })
      .catch(({ response }) => {
        if (response?.status !== 404) {
          message.error(i18nInstance.t('store.notes.getNoteFailure'))
          actions.setNotesError(true)
        }
      })
      .finally(() => {
        actions.setLoadingNotes(false)
      })
  }),

  addNote: thunk(async (actions, { caseId, text }) => {
    try {
      await createCaseNote(caseId, text)

      const updatedNotes = await listCaseNotes(caseId)

      actions.setCaseNotes(updatedNotes.data.notes)
    } catch (e) {
      console.error(e)
      message.error(i18nInstance.t('store.notes.addNoteFailure'))
    }
  }),

  addNoteWithFiles: thunk(async (actions, { caseId, text, files }) => {
    actions.setLoadingNotes(true)

    try {
      const { data: createdNote } = await createCaseNote(caseId, text)

      const attachFileCalls = files.map((file) =>
        attachFileToInvestigationNote({
          file,
          fileName: file.name,
          investigationId: caseId,
          noteId: createdNote.noteId,
        })
      )

      await Promise.all(attachFileCalls)
    } catch {
      message.error(i18nInstance.t('store.notes.addNoteFailure'))
    }

    return actions.getCaseNotes(caseId)
  }),

  removeNote: thunk((actions, { caseId, noteId }) => {
    return deleteCaseNote(caseId, noteId)
      .then(({ data }) => {
        actions.setCaseNotes(data)
        message.success(i18nInstance.t('store.notes.apiNoteDeleteSuccess'))
      })
      .catch(() => {
        message.error(i18nInstance.t('store.notes.deleteNoteFailure'))
      })
  }),

  clearCaseNotes: action((state) => {
    state.notes = []
  }),
}

export default caseNotesModel
