import { FC, useEffect, useRef } from 'react'
import { CloseOutlined } from '@ant-design/icons'
import { Divider, Row, Col, Flex } from 'antd'
import { colorSlate } from '@signifyd/colors'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'
import { Text, ErrorBoundary, MagnifyingGlass } from '@signifyd/components'
import ResponsiveIconButton from 'core/components/ResponsiveIconButton'
import { SavedFilter } from 'store/search'
import { spacingMD } from '@signifyd/ant'
import styles from './ThirdGenSearchContainer.less'
import QuickViews from '../../QuickViews'
import SearchSummary from '../../SearchSummary'
import ThirdGenSavedFilters from '../../SavedFilters/thirdGen/ThirdGenSavedFilters'

interface Props {
  searchTerm: string
  editing: boolean
  onSearchTermChange: (search: string) => void
  isInvalidSearch: boolean
  onClear: () => any
  onFocus: () => any
  onSearch: () => any
  onQuickSearch: (key: string) => any
  onSavedFilter: (key: string) => any
  matchingSavedFilter: SavedFilter | undefined
  hasAirlineOrders: boolean
}

const ThirdGenSearchContainer: FC<Props> = ({
  onSearchTermChange,
  searchTerm,
  editing,
  isInvalidSearch,
  onClear,
  onFocus,
  onSearch,
  onQuickSearch,
  onSavedFilter,
  matchingSavedFilter,
  hasAirlineOrders,
}) => {
  const { t } = useTranslation()
  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (editing) {
      inputRef.current?.focus()
    }
  }, [inputRef, editing])

  const placeholderText = hasAirlineOrders
    ? 'airlinePlaceholder'
    : 'placeholder'

  return (
    <>
      <Row onClick={onFocus}>
        <div
          data-analytics-id="search-bar"
          className={styles.searchBar}
          data-test-id="searchBar"
        >
          <Flex gap={spacingMD} align="center">
            <MagnifyingGlass className={styles.searchIcon} fill={colorSlate} />

            {editing ? (
              <>
                <Flex className={styles.inputContainer} vertical>
                  <input
                    data-test-id="searchBarInput"
                    className={styles.input}
                    placeholder={t(`search.searchBar.${placeholderText}`)}
                    value={searchTerm}
                    onChange={(e) => {
                      onSearchTermChange(e.target.value)
                    }}
                    ref={inputRef}
                    onKeyPress={(e) => {
                      if (e.key === 'Enter') {
                        onSearch()
                      }
                    }}
                  />
                  {isInvalidSearch && (
                    <>
                      <Text data-test-id="invalidSearch" type="danger">
                        {t('search.searchBar.invalidSearch')}
                      </Text>
                    </>
                  )}
                </Flex>
                {!!searchTerm && (
                  <CloseOutlined
                    data-test-id="clearButton"
                    className={cx([styles.icon, styles.clearIcon])}
                    onClick={() => onSearchTermChange('')}
                  />
                )}
              </>
            ) : (
              <>
                <SearchSummary
                  placeholderText={t(`search.searchBar.${placeholderText}`)}
                />
                <ResponsiveIconButton
                  buttonClass={styles.clearButton}
                  onClick={onClear}
                  text={t('search.buttons.clearSearch')}
                  Icon={<CloseOutlined />}
                />
              </>
            )}
          </Flex>
        </div>
      </Row>

      {editing && (
        <>
          <Divider className={styles.divider} />

          <div className={styles.quickSearch}>
            <Row>
              <Col xs={{ span: 21 }} lg={{ span: 8 }}>
                <QuickViews onClick={onQuickSearch} />
              </Col>
              <Col xs={{ span: 21 }} lg={{ span: 15 }}>
                <ErrorBoundary
                  message={t('errorBoundaries.savedFilters')}
                  className={styles.errorBoundary}
                >
                  <ThirdGenSavedFilters
                    onClick={onSavedFilter}
                    matchingSavedFilter={matchingSavedFilter}
                  />
                </ErrorBoundary>
              </Col>
            </Row>
          </div>
        </>
      )}
    </>
  )
}

export default ThirdGenSearchContainer
