import {
  CustomerNavBar,
  NavigationBar,
  ShadowUserBanner,
  useIsThirdGen,
} from '@signifyd/components'
import useUpdateUiState from '@signifyd/components/src/framework/UserProvider/mutations/useUpdateUIState'
import {
  filterMenuItemByAuth,
  getNavigationItems,
} from '@signifyd/components/src/thirdGenComponents/NavigationBar/navigation.utils'
import { FC } from 'react'
import { Layout } from 'antd'
import AppRoutes from 'AppRoutes'
import { useStoreState } from 'store'
import { PageSizeProvider } from '../PageSizeContext'

const { Content } = Layout

const AppLayout: FC = () => {
  const currentUser = useStoreState((state) => state.user.currentUser)!
  const userConfigs = useStoreState((state) => state.user.userConfigs)!
  const updateUiState = useUpdateUiState(currentUser)
  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    const userRolesSet = new Set(currentUser.roles)

    const items = filterMenuItemByAuth(
      getNavigationItems(),
      userRolesSet,
      currentUser
    )

    return (
      <Layout style={{ height: '100vh' }}>
        <NavigationBar
          user={currentUser}
          userConfigs={userConfigs}
          onLanguageSelectionChange={async (selectedLocale) => {
            await updateUiState.mutateAsync({ locale: selectedLocale })
          }}
          items={items}
        />

        <Content>
          <AppRoutes />
          <ShadowUserBanner currentUser={currentUser} />
        </Content>
      </Layout>
    )
  }

  return (
    <Content>
      <CustomerNavBar
        user={currentUser}
        userConfigs={userConfigs}
        onLanguageSelectionChange={(selectedLocale) => {
          updateUiState.mutate({
            locale: selectedLocale,
          })
        }}
      />
      <Content>
        <PageSizeProvider>
          <AppRoutes />
          <ShadowUserBanner currentUser={currentUser} />
        </PageSizeProvider>
      </Content>
    </Content>
  )
}

export default AppLayout
