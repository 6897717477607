import { computed, Computed } from 'easy-peasy'
import { StoreModel } from 'store'

export * from './types'

interface CaseActionModel {
  canSeeGuaranteeActions: Computed<CaseActionModel, boolean, StoreModel>
}

export const caseActionModel: CaseActionModel = {
  // action section visibility
  canSeeGuaranteeActions: computed(
    [(_state, storeState) => storeState.currentCase.details],
    (caseDetails) =>
      !(
        caseDetails?.guaranteeEligible === 'false' &&
        caseDetails?.guaranteeDisposition === null
      )
  ),
}
