import { FC } from 'react'
import { Tooltip } from 'antd'
import { colorMarengo, colorYonder } from '@signifyd/colors'
import cx from 'classnames'
import styles from './ActionIconButton.less'
import { ActionIconButtonProps } from './ActionIconButton.types'

const ActionIconButton: FC<ActionIconButtonProps> = ({
  Icon,
  tooltip,
  className,
  onClick,
  actionState = { disabled: false },
  isLoading,
  testId,
  iconColor = colorMarengo,
}) => {
  const disabled = isLoading || actionState.hidden || actionState.disabled

  return (
    <Tooltip
      title={!disabled ? tooltip : actionState.disabledReason}
      placement="bottom"
      // without this, tooltip persists after click if ui refreshes
      trigger={['hover', 'click']}
    >
      <button
        type="button"
        className={cx(styles.actionIcon, className)}
        onClick={onClick}
        data-test-id={testId}
        disabled={disabled}
      >
        <Icon fill={disabled ? colorYonder : iconColor} />
      </button>
    </Tooltip>
  )
}

export default ActionIconButton
