import { FC } from 'react'
import { colorMarengo, colorCerulean } from '@signifyd/colors'
import {
  TextThirdGen,
  Space,
  SquareCheckInactive,
  SquareCheckActive,
} from '@signifyd/components'
import cx from 'classnames'
import { Flex, Divider, Typography } from 'antd'
import { INV_GUARANTEE_DISPOSITION, SEARCH_FIELD } from '@signifyd/http'
import { useNavigate } from 'react-router'
import { FilterState } from 'store/search'
import { useStoreActions } from 'store'
import { handleArrayChange } from '../aggregate.utils'
import styles from './AggregateView.less'

const defaultCaseSearch = {
  quickSearchKey: undefined,
  saveAs: '',
  savedFilterKey: undefined,
}

interface NewFilter {
  field?: INV_GUARANTEE_DISPOSITION
  claims?: Partial<FilterState>
}

export interface SubTotal {
  value: number | string
  title: string
  newFilter: NewFilter
  isSelected: boolean
  id: string
}

interface Props {
  subTotals: Array<SubTotal>
  filters: FilterState
  updateFilters: (filters: FilterState) => void
}

const AggregateView: FC<Props> = ({ subTotals, filters, updateFilters }) => {
  const navigate = useNavigate()
  const { searchCases } = useStoreActions((actions) => actions.search)

  const handleClick = ({ field, claims = {} }: NewFilter): void => {
    const guarantees = field
      ? {
          [SEARCH_FIELD.guaranteeDisposition]: handleArrayChange(
            field,
            filters.guaranteeDisposition
          ),
        }
      : {}

    updateFilters({
      ...filters,
      ...guarantees,
      ...claims,
    })

    searchCases({ ...defaultCaseSearch, navigate })
  }

  return (
    <Flex className={styles.aggregateContainer}>
      {subTotals.map(({ title, value, newFilter, isSelected, id }, i) => (
        <Flex flex={1} key={id}>
          {i !== 0 && (
            <Divider
              type="vertical"
              orientationMargin={4}
              className={styles.divider}
            />
          )}
          <Flex
            key={title}
            className={cx([styles.item, isSelected && styles.active])}
            onClick={() => handleClick(newFilter)}
            data-test-id={`aggregatePanel${id}`}
            data-analytics-id={`aggregate-panel-${id}`}
            vertical
          >
            <TextThirdGen className={styles.title} size="sm" weight="semibold">
              {title}
            </TextThirdGen>

            <Space size="md" />
            <Flex justify="space-between" align="center">
              <Typography.Title
                className={styles.value}
                color={colorMarengo}
                level={1}
              >
                {value}
              </Typography.Title>

              {isSelected ? (
                <SquareCheckActive
                  className={styles.icon}
                  fill={colorCerulean}
                />
              ) : (
                <SquareCheckInactive className={styles.icon} />
              )}
            </Flex>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default AggregateView
