import {
  colorCerulean,
  colorYonderLight80,
  colorWhite,
  colorLicorice,
  colorHaze,
} from '@signifyd/colors'
import { SigTag, useIsThirdGen } from '@signifyd/components'
import { SEARCH_FIELD } from '@signifyd/http'
import { Tag } from 'antd'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import { getPastStartDate, getEndOfDay } from 'core/utils/date.utils'
import { FilterState } from 'store/search'
import { useStoreActions } from 'store'
import styles from './HeadlineDatePicker.less'

interface Props {
  dayCount: number
  isSelected: boolean
  updateFilters: (filters: FilterState) => void
  filters: FilterState
}

const DatePickerTag: FC<Props> = ({
  dayCount,
  isSelected,
  updateFilters,
  filters,
}) => {
  const { t } = useTranslation()
  const { searchCases, setCurrentPage } = useStoreActions(
    (actions) => actions.search
  )
  const navigate = useNavigate()

  const isThirdGen = useIsThirdGen()

  if (isThirdGen) {
    return (
      <Tag
        key={dayCount}
        color={isSelected ? colorCerulean : colorHaze}
        style={{
          color: isSelected ? colorWhite : colorLicorice,
        }}
        className={styles.date}
        data-analytics-id={`headline-date-picker-${dayCount}`}
        data-test-id={`headlineDatePicker${dayCount}${
          isSelected ? 'Active' : ''
        }`}
        onClick={() => {
          updateFilters({
            ...filters,
            ...{
              [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
                min: getPastStartDate({
                  subtractInterval: 'd',
                  subtractValue: dayCount,
                }),
                max: getEndOfDay(),
              },
            },
          })
          setCurrentPage(1)
          searchCases({
            quickSearchKey: undefined,
            saveAs: '',
            savedFilterKey: undefined,
            navigate,
          })
        }}
      >
        {t('aggregates.lastDays', { count: dayCount })}
      </Tag>
    )
  }

  return (
    <SigTag
      key={dayCount}
      type="primary"
      color={isSelected ? colorCerulean : colorYonderLight80}
      style={{ color: isSelected ? colorWhite : colorLicorice }}
      className={styles.date}
      data-analytics-id={`headline-date-picker-${dayCount}`}
      data-test-id={`headlineDatePicker${dayCount}${
        isSelected ? 'Active' : ''
      }`}
      onClick={() => {
        updateFilters({
          ...filters,
          ...{
            [SEARCH_FIELD.normalizedPurchaseCreatedAt]: {
              min: getPastStartDate({
                subtractInterval: 'd',
                subtractValue: dayCount,
              }),
              max: getEndOfDay(),
            },
          },
        })
        setCurrentPage(1)
        searchCases({
          quickSearchKey: undefined,
          saveAs: '',
          savedFilterKey: undefined,
          navigate,
        })
      }}
    >
      {t('aggregates.lastDays', { count: dayCount })}
    </SigTag>
  )
}

export default DatePickerTag
