import { FC } from 'react'
import { Descriptions, Flex } from 'antd'
import { toTitleCase } from '@signifyd/utils'
import { CaseOrder, InvestigationInfo } from '@signifyd/http'
import { useTranslation } from 'react-i18next'
import { compact } from 'lodash'
import NoInformation from 'core/components/NoInformation'
import DiscountCodes from 'pages/CaseReviewPage/components/DiscountCodes'
import SeederLabel from 'pages/CaseReviewPage/components/SeederLabel'
import { useCaseEntries } from 'core/hooks/useCaseEntries'
import { DescriptionsItemType } from 'antd/es/descriptions'
import { spacingSM } from '@signifyd/ant'
import styles from './PaymentSummary.less'
import PaymentSummaryIcons from './PaymentSummaryIcons'

interface Props {
  orderDetails: CaseOrder
  caseDetails: InvestigationInfo
}

export const PaymentSummaryThirdGen: FC<Props> = ({
  orderDetails,
  caseDetails,
}) => {
  const { t } = useTranslation()
  const { data: caseEntries } = useCaseEntries(caseDetails.investigationId)

  const {
    authorizationTransactionId,
    avsResponseCode,
    avsResponseDescription,
    authorizationGatewayStatus,
    authorizationFailureReason,
    cardBin,
    cardLastFourDigits,
    currency,
    cvvResponseCode,
    cvvResponseDescription,
    discountCodes,
    scaEvaluation,
    normalizedCardNumber,
  } = caseDetails

  const {
    paymentMethod: {
      bankAuthNumber,
      cardExpiryYear,
      cardExpiryMonth,
      paymentTerms,
      type,
    },
  } = orderDetails

  const noInfo =
    !authorizationGatewayStatus &&
    !avsResponseCode &&
    !cardBin &&
    !(cardExpiryMonth && cardExpiryYear) &&
    !cardLastFourDigits &&
    !caseEntries?.creditCardIssuer?.details?.bankName &&
    !cvvResponseCode &&
    !discountCodes?.length &&
    !paymentTerms?.length &&
    !scaEvaluation &&
    !normalizedCardNumber

  const sectionName = t('caseSummary.payment.title')

  if (noInfo) {
    return (
      <div data-test-id="paymentSummary.noInfo">
        <Descriptions
          title={
            <>
              {sectionName}
              <PaymentSummaryIcons orderDetails={orderDetails} />
            </>
          }
          size="small"
        />
        <NoInformation />
      </div>
    )
  }

  const scaEvalOutcome = scaEvaluation?.outcome
  const scaEvalDetail =
    scaEvaluation?.exclusionDetails?.exclusion ||
    scaEvaluation?.exemptionDetails?.exemption

  const scaKey = compact([scaEvalOutcome, scaEvalDetail]).join('_')

  const items: Array<DescriptionsItemType> = [
    cvvResponseCode && {
      label: t('caseSummary.payment.cvvResponse'),
      children: (
        <span data-test-id="paymentSummary.cvvResponse">
          {cvvResponseDescription} ({cvvResponseCode})
        </span>
      ),
    },
    avsResponseCode && {
      label: t('caseSummary.payment.avsResponse'),
      children: (
        <span data-test-id="paymentSummary.avsResponse">
          {avsResponseDescription} ({avsResponseCode})
        </span>
      ),
    },
    normalizedCardNumber && {
      label: (
        <SeederLabel
          dataTestId="seeder-credit-number-label"
          label={t('caseSummary.payment.cardNumber')}
        />
      ),
      children: (
        <span data-test-id="paymentSummary.cardNumber">
          {normalizedCardNumber}
        </span>
      ),
    },
    cardExpiryMonth &&
      cardExpiryYear && {
        label: t('caseSummary.payment.expiry'),
        children: (
          <span data-test-id="paymentSummary.cardExpiry">
            {cardExpiryMonth} / {cardExpiryYear}
          </span>
        ),
      },
    caseEntries?.creditCardIssuer?.details?.bankName && {
      label: t('caseSummary.payment.bank'),
      children: (
        <span data-test-id="paymentSummary.bankName">
          {caseEntries.creditCardIssuer.details.bankName}
        </span>
      ),
    },
    authorizationGatewayStatus && {
      label: t('caseSummary.payment.authStatus'),
      children: (
        <span data-test-id="paymentSummary.authStatus">
          {toTitleCase(authorizationGatewayStatus)}
        </span>
      ),
    },
    scaEvaluation && {
      label: t('caseSummary.payment.scaEvaluation.label'),
      children: (
        <span
          className={styles.scaEvaluation}
          data-test-id="paymentSummary.scaEvaluation"
          data-analytics-id={`sca-evaluation-${scaKey}`}
        >
          {t('caseSummary.payment.scaEvaluation.detail', {
            context: scaKey,
          })}
        </span>
      ),
    },
    bankAuthNumber && {
      label: t('caseSummary.payment.authNumber'),
      children: (
        <span data-test-id="paymentSummary.authNumber">{bankAuthNumber}</span>
      ),
    },
    authorizationFailureReason && {
      label: t('caseSummary.payment.failureReason'),
      children: (
        <span data-test-id="paymentSummary.failureReason">
          {toTitleCase(authorizationFailureReason)}
        </span>
      ),
    },
    paymentTerms?.length && {
      label: t('caseSummary.payment.terms'),
      children: (
        <span data-test-id="paymentSummary.paymentTerms">
          {paymentTerms.join(', ')}
        </span>
      ),
    },
    type && {
      label: t('caseSummary.payment.authorizationTransactionId'),
      children: (
        <span data-test-id="paymentSummary.paymentType">
          {toTitleCase(type)}
        </span>
      ),
    },
    authorizationTransactionId && {
      label: t('caseSummary.payment.paymentType'),
      children: (
        <span data-test-id="paymentSummary.authorizationTransactionId">
          {authorizationTransactionId}
        </span>
      ),
    },
    discountCodes?.length && {
      label: t('caseSummary.payment.discountCodes'),
      children: (
        <span data-test-id="paymentSummary.discountCodeContent">
          <DiscountCodes discountCodes={discountCodes} currency={currency} />
        </span>
      ),
    },
  ].filter((x) => !!x) as Array<DescriptionsItemType>

  return (
    <>
      <Descriptions
        title={
          <Flex align="center" gap={spacingSM}>
            {sectionName}
            <PaymentSummaryIcons orderDetails={orderDetails} />
          </Flex>
        }
        items={items}
        colon={false}
        size="small"
        bordered
        column={1}
      />
    </>
  )
}
export default PaymentSummaryThirdGen
