import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Tabs, Card, TabsProps } from 'antd'
import {
  StretchToPageBottom,
  P,
  ErrorBoundary,
  Space,
  useIsThirdGen,
} from '@signifyd/components'
import { colorSnow, colorWhite } from '@signifyd/colors'
import { useParams } from 'react-router'
import { useStoreActions, useStoreState } from 'store'
import PageWrapper from 'core/components/PageWrapper'
import PageSpinner from 'core/components/PageSpinner'
import NotFound from 'core/components/NotFound'
import CaseHeader from './containers/CaseHeader'
import CaseHeaderNavigation from './containers/CaseHeaderNavigation'
import CaseActions from './containers/CaseActions'
import CaseActivity from './containers/CaseActivity'
import ExpandableNoteInput from './components/ExpandableNoteInput'
import TabbedCaseDisplay from './containers/TabbedCaseDisplay'
import styles from './CaseReviewPage.less'

export const CaseReviewPage: FC = () => {
  const { t } = useTranslation()
  const { caseId } = useParams()

  const { getCurrentCase, clearCurrentCase } = useStoreActions(
    (actions) => actions.currentCase
  )
  const caseDetails = useStoreState((state) => state.currentCase.details)
  const caseNotFound = useStoreState((state) => state.currentCase.caseNotFound)
  const serverError = useStoreState(
    (state) => state.currentCase.detailsServerError
  )
  const loading = useStoreState((state) => state.currentCase.loading)
  const notes = useStoreState((state) => state.currentCase.notes)

  const isThirdGen = useIsThirdGen()

  useEffect(() => {
    if (caseId) {
      getCurrentCase(+caseId)
    }

    return () => clearCurrentCase()
  }, [getCurrentCase, clearCurrentCase, caseId])

  const tabPanels: TabsProps['items'] = caseDetails
    ? [
        {
          /* Actions Tab */
          key: 'actions',
          label: t('caseActions.tabTitle'),
          children: (
            <ErrorBoundary message={t('errorBoundaries.actions')}>
              <CaseActions caseDetails={caseDetails} notes={notes} />
            </ErrorBoundary>
          ),
        },
        {
          /* Activity Tab */
          key: 'activity',
          label: t('caseActivity.tabTitle'),
          children: (
            <ErrorBoundary message={t('errorBoundaries.activity')}>
              <CaseActivity />
            </ErrorBoundary>
          ),
        },
      ]
    : []

  return (
    <div className={!isThirdGen ? styles.wrapper : undefined}>
      <CaseHeaderNavigation />
      <PageWrapper
        className={styles.caseReviewPage}
        backgroundColor={isThirdGen ? colorWhite : colorSnow}
      >
        <Space size="sm" />
        <ErrorBoundary message={t('errorBoundaries.orderDetails')}>
          <CaseHeader caseDetails={caseDetails} />
        </ErrorBoundary>

        {loading && <PageSpinner />}

        {!loading && !serverError && caseNotFound && (
          <NotFound title={t('pages.caseReview.noOrderFound.title')}>
            <P>{t('pages.caseReview.noOrderFound.subTitle')}</P>
          </NotFound>
        )}

        {!loading && serverError && (
          <NotFound title={t('pages.caseReview.errorLoadingOrder.title')}>
            <P>{t('pages.caseReview.errorLoadingOrder.subTitle')}</P>
          </NotFound>
        )}
        {!loading && !caseNotFound && caseDetails && (
          <div className={styles.container}>
            <TabbedCaseDisplay />

            {!isThirdGen && (
              <StretchToPageBottom
                marginBottom={24}
                className={styles.sidePanel}
              >
                <Card
                  className={styles.sidePanel}
                  styles={{ body: { height: '100%' } }}
                >
                  <Tabs
                    // do NOT turn animation on, it will break the layout
                    // and hide second tab will no longer be visible
                    animated={false}
                    centered
                    size="small"
                    className={styles.tabContainer}
                    tabBarStyle={{ textAlign: 'center' }}
                    renderTabBar={(props, DefaultTabBar) => (
                      <>
                        <DefaultTabBar {...props} />
                        <ExpandableNoteInput
                          caseId={caseDetails.investigationId}
                        />
                      </>
                    )}
                    items={tabPanels}
                  />
                </Card>
              </StretchToPageBottom>
            )}
          </div>
        )}
      </PageWrapper>
    </div>
  )
}

export default CaseReviewPage
