import {
  CreateRerouteRequest,
  Recipient,
  PhysicalAddress,
  Shipment as V2Shipment,
  V3Address,
  V3Recipient,
  V3Shipment,
} from '@signifyd/http'
import {
  Address,
  UpdateAddressRequiredFields,
} from '../types/updateAddress.types'

/**
 * Only use this conversion if you've performed validation prior to using that streetAddress, postalCode, countryCode
 * and city are present on the `PhysicalAddress` supplied. The V3 address model requires them.
 */
const toRerouteAddress = (address: PhysicalAddress): V3Address => ({
  streetAddress: address.streetAddress!,
  postalCode: address.postalCode!,
  provinceCode: address.provinceCode || undefined,
  countryCode: address.countryCode!,
  unit: address.unit || undefined,
  city: address.city!,
})

export const toRerouteRecipient = (recipient: Recipient): V3Recipient => ({
  address: toRerouteAddress(recipient.address!),
  organization: recipient?.organization || undefined,
  fullName: recipient?.fullName || undefined,
})

export const toRerouteShipment = (
  recipient: Recipient,
  shipment?: V2Shipment
): V3Shipment => ({
  destination: toRerouteRecipient(recipient),
  shipmentId: shipment?.shipmentId,
  carrier: shipment?.shipper,
  minDeliveryDate: shipment?.minDeliveryDate,
  maxDeliveryDate: shipment?.maxDeliveryDate,
})

export const toRerouteRequest = (
  addresses: Array<Address>,
  caseDetails: UpdateAddressRequiredFields
): CreateRerouteRequest => ({
  orderId: caseDetails.orderExternalId,
  shipments: addresses.map((address) =>
    toRerouteShipment(
      address.newAddress ?? address.oldAddress,
      address.shipment
    )
  ),
})
